<template>
<div class="main-container">
  <div class="left-nav-content">
    <leftNav :navList="leftNavList" @onSelect="handleNavSelect"></leftNav>
  </div>
  <div class="router-view-content">
    <router-view @refreshSetting="refreshSetting"></router-view>
  </div>
</div>
</template>

<script>
import leftNav from "@/components/leftNav/index.vue";

export default {
  name: "index",
  components: {leftNav},
  data() {
    return {
      leftNavList: [{
        title: '上传图片',
        name: 'upload',
        index: 'upload',
      }]
    }
  },
  created() {
    this.$router.push({ name: 'upload' }, () => {})
    this.initData()
  },
  methods: {
    initData() {
    
    },
    handleNavSelect(navItem) {
      this.$router.push({ name: navItem.name },() => {})
    },
    refreshSetting() {
    
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  display: flex;
  
  .router-view-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: #fff;
    padding: 20px 30px;
  }
}
</style>