<template>
  <div class="home-page-container">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <span class="title-content">{{ msg }}</span>
      <el-button size="medium" type="primary" plain @click="handleLogout">退出登录</el-button>
    </div>
  </div>
</template>
  
  <script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import axios from "axios";

export default {
  name:'WelcomePage',
  data() {
    return {
      msg: '欢迎使用本系统'
    };
  },
  computed: {
    ...mapGetters(['loginStatus']),
  },
  mounted() {
    axios.post(`${this.$base_url}/pa/textRecognition/getTextTest`, {})
  },
  methods:{
    ...mapActions({
      getOrderList: 'getOrderList',
    }),
    ...mapMutations([
      'setLoginStatus'
    ]),
    handleLogout () {
      this.setLoginStatus(false)
      localStorage.removeItem("loggedIn")
    },
    test(){
      for (let i = 0; i < 100; i++) {
        let params = {
          page: 1,
          pageSize: 200,
        }
        this.getOrderList(params).then(res => {
          if(res.paCode !== '000') {
            this.$message.error('获取用户订单失败' + res.paMsg);
          } else {
            console.log("erpData:" ,res.paData)
          }
        })
      }
      
    }
  }
};
</script>

<style>
.home-page-container {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  
  .title-content {
    font-size: 40px;
    font-weight: 600;
  }
}
</style>