<template>
<div class="view-content">
  <el-button @click="handleUpload">上传运费单</el-button>
  <CommonDrawer
    :propParams="drawerData"
    :showDrawer="showDrawer"
    size="60%"
    @close="showDrawer = false"
  >
    <template slot="drawer-content">
      <div class="drawer-content">
        <div>
          <i class="el-icon-folder-add" style="font-size: 18px; margin-bottom: 16px;">上传出货单</i>
        </div>
        <el-upload
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div>将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div>
          <i class="el-icon-folder-add" style="font-size: 18px; margin-bottom: 16px;">上传出货单</i>
        </div>
        <el-upload
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div>将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </template>
    <div slot="drawer-footer">
      <el-button type="primary" @click="handleSubmit">保存</el-button>
      <el-button @click="closeDetailDrawer">关闭</el-button>
    </div>
  </CommonDrawer>
</div>
</template>

<script>
import CommonDrawer from "@/components/common/commonDrawer.vue";

export default {
  name: "upload",
  components: {CommonDrawer},
  data() {
    return {
      drawerData: {
        title: '上传运费单',
        showFooter: true
      },
      showDrawer: false
    }
  },
  methods: {
    handleUpload() {
      this.showDrawer = true
    },
    handleSubmit() {
    
    },
    closeDetailDrawer() {
      this.showDrawer = false
    }
  }
}
</script>

<style scoped lang="less">
.view-content {
  
  .drawer-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    
    .upload-content {
      width: 200px;
    }
  }
}
</style>