import OrderManagement from "@/components/orderManage/index.vue";
import userOrder from "@/components/orderManage/userOrderManage.vue";
import UnusualOrders from "@/components/orderManage/unusualOrderManage.vue";
import orderManageSetting from "@/components/orderManage/setting.vue"
import waitDispatch from '@/components/orderManage/waitDispatch.vue'
import longDistance from "@/components/orderManage/LongDistance.vue";

export default {
  router: {
    path: "/orderManagement",
    name: "OrderManagement",
    component: OrderManagement,
    props: true,
    children: [
      {
        path: "userOrder",
        name: "userOrder",
        component: userOrder,
        props: true
      }, {
        path: 'longDistance',
        name: 'longDistance',
        component: longDistance,
        props: true
      }, {
        path: "unUsualOrder",
        name: "unUsualOrder",
        component: UnusualOrders,
        props: true
      }, {
        path: 'orderManageSetting',
        name: 'orderManageSetting',
        component: orderManageSetting,
        props: true
      }, {
        path: 'waitDispatch',
        name: 'waitDispatch',
        component: waitDispatch,
        props: true
      }
    ]
  },
}