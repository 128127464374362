<template>
<div class="main-container order-manage-container">
  <div class="left-nav-content">
    <leftNav :navList="leftNavList" @on-select="handleNavSelect"></leftNav>
  </div>
  <div class="router-view-content">
    <router-view @refreshSetting="refreshSetting"></router-view>
  </div>
</div>
</template>

<script>
import leftNav from "@/components/leftNav/index.vue";
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "orderManage",
  components: {
    leftNav,
  },
  data() {
    return {
      leftNavList: [
        {
          title: '所有订单',
          name: 'userOrder',
          index: 'userOrder',
        }, {
          title: '长途订单',
          name: 'longDistance',
          index: 'longDistance'
        }, {
          title: '待派单',
          name: 'waitDispatch',
          index: 'waitDispatch',
        }, {
          title: '未支付',
          name: 'unUsualOrder',
          index: 'unUsualOrder',
        }, {
          title: '设置',
          name: 'orderManageSetting',
          index: 'orderManageSetting',
        }
      ]
    }
  },
  watch: {
    // 观察完整的路由对象
    '$route'(to, from) {
      if(to.path === '/orderManagement') {
        this.$router.push({ name: 'userOrder' }, () => {})
      }
      // 执行一些操作，比如数据获取或状态更新
    },
  },
  created() {
    this.$router.push({ name: 'userOrder' }, () => {})
    this.initData()
  },
  methods: {
    ...mapActions({
      getSetting: 'getSetting',
    }),
    ...mapMutations([
      'setState'
    ]),
    initData () {
      this.handleGetSetting()
    },
    handleGetSetting () {
      this.getSetting({
        settingGroup: 'orderManage'
      }).then(res => {
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '获取设置失败');
        } else {
          this.setState({
            stateName: 'orderManageSetting',
            value: res.paData
          })
        }
      })
    },
    handleNavSelect(navItem) {
      this.$router.push({ name: navItem.name },() => {})
    },
    refreshSetting () {
      this.handleGetSetting()
    }
  },
}
</script>

<style scoped>
.order-manage-container {
  display: flex;
  flex-direction: row;
}
.router-view-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  background-color: #fff;
  padding: 20px 30px;
}
</style>