import OSS from 'ali-oss'
import store from '../store/index';

export const uploadFile = (ObjName, file) => {
  try {
    const commonConfig = store.state.commonConfig
    const client = new OSS({
      // 地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
      region: 'oss-cn-shenzhen',
      authorizationV4: true,
      // 阿里云产品的通用id
      accessKeyId: commonConfig.accessKeyId,
      // 密钥
      accessKeySecret: commonConfig.accessKeySecret,
      // OSS 存储区域名
      bucket: 'zt-low-frequency',
      // 确保使用HTTPS
      secure: true,
    });
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
    // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
    return client.put(ObjName, file)
      .then(res => res)
      .catch(err => {
        console.log(err)
      })
  } catch (e) {
    console.log(e);
  }
}

export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}