import axios from 'axios'
import baseData from '@/baseData.js'
const baseUrl = baseData.baseUrl

export default {
  updateOrderInfo(params) {
    return axios.post(`${baseUrl}/coal/updateOrderInfo`, params)
  },
  getOrderList(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/list`, params)
  },
  getUnusualOrders(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/getUnusualOrder`, params)
  },
  editOrder (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/editOrder`, params)
  },
  getSetting (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/getSetting`, params)
  },
  setSetting (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/setSetting`, params)
  },
  removeOrder (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/removeOrder`, params)
  },
  refundOrder (params) {
    return axios.post(`${baseUrl}/userpay/refundOrder`, params)
  },
  deleteAllOrders (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/deleteAllOrders`, params)
  },
  setOrderStatus(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/setOrderStatus`, params)
  },
  checkRefundLicense(params) {
    return axios.post(`${baseUrl}/pa/pay/checkRefundLicense`, params)
  }
}