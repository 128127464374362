<template>
<div class="main-container">
  <vxe-grid
    v-bind="gridOptions"
    :loading="isLoading"
    :columns="tableColumns"
    :data="orderList">
    <template #top>
      <div class="top-content">
        <div style="height: 30px; display: flex; align-items: center;">
          <span class="fs-18 fw-bold title-content">订单管理-所有订单</span>
          <div class="total-count-content">订单数{{ totalCount }}</div>
          <i v-if="!isLoading" class="el-icon-refresh pointer refresh-icon" @click="refreshData"></i>
          <i v-if="isLoading" class="el-icon-loading pointer refresh-icon"></i>
        </div>
        <div style="height: 30px; display: flex; align-items: center;">
          <el-button size="small" style="width: 90px;" @click="handleExport">导出</el-button>
        </div>
      </div>
    </template>
    <template #search-filter="{column}">
      <div class="flex flex-row justify-space-between">
        <span>{{ column.title }}</span>
        <div class="flex">
          <span class="reset-btn pointer" v-show="showHeaderReset(column)" @click="resetSearch(column)">重置</span>
          <el-popover
            ref="popoverRef"
            popper-class="search-filter-popover"
            placement="bottom">
            <div slot="reference">
              <i class="el-icon-search pointer"/>
            </div>
            <div class="search-input-wrapper">
              <el-input
                v-model="searchKeyWords[column.field]"
                :clearable="true"
                class="search-input"
                @keyup.enter.native="handleSearch(column)"
                placeholder="请输入关键字"/>
              <span class="search-confirm" @click="handleSearch(column)">确定</span>
            </div>
          </el-popover>
        </div>
      </div>
    </template>
    <template #can-edit-content="{row, column}">
      <div v-if="column.field === 'licensePlate'" class="license-plate-content">
        <span>{{ row.licensePlate }}</span>
        <i class="el-icon-document-copy pointer" @click="copyContent(row.licensePlate)"></i>
      </div>
    </template>
    <template #order-time="{column, row}">
      <span>{{ formatTime(row.orderTime) }}</span>
    </template>
    <!-- 订单状态 -->
    <template #order-status="{column, row}">
      <div style="display: flex; gap: 14px;">
        <span>{{ orderStatusText(row.orderStatus) }}</span>
        <div v-if="row.orderStatus === 1">
          <i
            class="el-icon-mobile-phone"
            style="color: #4a90e2; cursor: pointer;"
            @click="handleSetOrderStatus({id: row.coalOrderId, status: 2})"
          >派单</i>
        </div>
      </div>
    </template>
    <!-- 运费单 -->
    <template #freight-rate-list="{column, row}">
      <span v-if="row.freightRateList" @click="handleViewImg(row)" class="pointer" style="color: #4a90e2">查看图片</span>
      <span v-else>/</span>
      <div v-show="false">
        <el-image
          ref="previewImg"
          :src="url"
          :preview-src-list="srcList">
        </el-image>
      </div>
    </template>
    <template #handle-operation="{row}">
      <div class="operate-content">
        <span class="pointer edit-btn" @click="handleEdit(row)">编辑</span>
        <el-divider direction="vertical"></el-divider>
        <span v-if="row.orderStatus !== 3 && row.orderPrice !== 0" class="edit-btn pointer" @click="handleRefund(row)">退款</span>
        <!-- 灰色的退款按键，不可点击 -->
        <span v-else style="color: #bbbbbb; cursor: default;">退款</span>
        <el-divider direction="vertical"></el-divider>
        <el-popconfirm
          confirm-button-text='确定'
          cancel-button-text='取消'
          icon="el-icon-info"
          icon-color="red"
          title="是否确认删除该条订单？"
          @confirm="handleDelete(row)"
        >
          <div slot="reference">
            <span class="pointer del-btn">删除</span>
          </div>
        </el-popconfirm>
      </div>
    </template>
    <template #pager>
      <vxe-pager
        :total="totalCount"
        :page-size="pageSize"
        :current-page.sync="page"
        :page-sizes="[20, 30, 50]"
        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump']"
        @page-change="handlePageChange">
      </vxe-pager>
    </template>
  </vxe-grid>
  <CommonDrawer
    class="drawer-content"
    :propParams="orderEditParams"
    :showDrawer="orderEditVisible"
    @close="orderEditVisible = false"
  >
    <div slot="drawer-content" class="order-info-content">
      <el-form label-width="118px" v-model="selectedOrderInfo">
        <el-form-item label="微信订单号" prop="outTradeNo">
          <el-input v-model="selectedOrderInfo.outTradeNo" disabled />
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="selectedOrderInfo.userName" />
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="selectedOrderInfo.licensePlate" />
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input v-model="selectedOrderInfo.telNumber" />
        </el-form-item>
        <el-form-item label="目的地">
          <el-input v-model="selectedOrderInfo.destination" disabled />
        </el-form-item>
        <el-form-item label="运费">
          <el-input v-model="selectedOrderInfo.price" disabled />
        </el-form-item>
        <el-form-item label="煤种">
          <el-input v-model="selectedOrderInfo.coalVar" disabled />
        </el-form-item>
        <el-form-item label="提煤单号">
          <div style="display: flex; gap: 8px;">
            <el-input v-model="selectedOrderInfo.coalBillNumber" />
            <el-button size="medium" @click="quickFill">快速填入</el-button>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="selectedOrderInfo.remark" :rows="5" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="drawer-footer">
      <el-button size="medium" @click="orderEditVisible = false">取消</el-button>
      <el-button size="medium" type="primary" @click="handleEditOrder">提交</el-button>
    </div>
  </CommonDrawer>
  <el-dialog
    title="请输入退款验证码"
    :visible="showConfirmRefund"
    destroy-on-close
    :close-on-click-modal="false"
    @close="showConfirmRefund = false"
  >
    <div style="margin-bottom: 8px;">正在退款：{{refundInfo.userName}}-{{refundInfo.licensePlate}}-{{refundInfo.telNumber}}</div>
    <el-input v-model="refundLicense" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="showConfirmRefund = false">取 消</el-button>
      <el-button type="primary" @click="handleCheckRefundLicense">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dayjs from "dayjs";
import CommonDrawer from "@/components/common/commonDrawer.vue";
import { tableExport } from "@/utils/tableExportUtil"

require('dayjs/locale/zh-cn');
dayjs.locale('zh-cn');
export default {
  name: "userOrderManage",
  components: {CommonDrawer},
  data() {
    return {
      gridOptions: {
        rowClassName: this.setRowClass,
        height: 'auto',
        border: 'full',
        round: true,
        showOverflow: 'tooltip',
        columnConfig: {
          resizable: true,
        },
        rowConfig: {
          isHover: true,
          height: 38,
        },
      },
      tableColumns: [
        {
          field: 'userName',
          title: '姓名',
          width: 120,
        }, {
          field: 'licensePlate',
          title: '车牌号',
          width: 120,
          minWidth: 120,
          slots: {
            header: 'search-filter',
            default: 'can-edit-content',
          },
        }, {
          field: 'telNumber',
          title: '电话号码',
          width: 120,
        }, {
          title: '装货',
          width: 140,
          formatter: ({ row }) => row.loadingAddress || '/'
        }, {
          field: 'destination',
          title: '卸货',
          width: 140,
          slots: {
            header: 'search-filter'
          },
        }, {
          field: 'price',
          title: '运费',
          width: 64,
        }, {
          field: 'coalVar',
          title: '煤种',
          width: 130,
          slots: {
            header: 'search-filter'
          },
        }, {
          field: 'orderStatus',
          title: '订单状态',
          width: 160,
          slots: {
            default: 'order-status'
          },
        }, {
          field: 'orderTime',
          title: '下单时间',
          slots: {
            default: 'order-time'
          },
          width: 200,
        }, {
          field: 'freightRateList',
          title: '运费单',
          slots: {
            default: 'freight-rate-list'
          },
          width: 100,
        }, {
          field: 'orderPrice',
          title: '下单费用',
          width: 80
        }, {
          field: 'outTradeNo',
          title: '微信订单号',
          minWidth: 200,
          width: 200,
        }, {
          field: 'remark',
          title: '备注',
          minWidth: 200,
        }, {
          field: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 160,
          slots: {
            default: 'handle-operation'
          },
        }
      ],
      isLoading: false,
      page: 1,
      pageSize: 20,
      totalCount: 0,
      orderList: [],
      searchKeyWords: {},
      requestParams: {
        search: {}
      },
      orderEditVisible: false,
      orderEditParams: {
        title: '编辑订单',
        showFooter: true,
      },
      selectedOrderInfo: {},
      billNumberDefaultValue: '',
      url: '',
      srcList: [''],
      showConfirmRefund: false,
      refundInfo: {},
      refundLicense: ''
    }
  },
  computed: {
    ...mapState([
      'orderManageSetting'
    ]),
  },
  watch: {
    orderManageSetting: {
      handler (newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          this.pageSize = newVal.defaultShowRowNum.data || 20
          this.billNumberDefaultValue = newVal.billNumberDefaultValue.data || ''
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      getOrderList: 'getOrderList',
      editOrder: 'editOrder',
      removeOrder: 'removeOrder',
      refundOrder: 'refundOrder',
      setOrderStatus: 'setOrderStatus',
      checkRefundLicense: 'checkRefundLicense'
    }),
    setRowClass ({ row }) {
      if (row.orderStatus === 2) {
        return 'is-assigned-order'
      } else {
        return  ''
      }
    },
    formatParams() {
      this.page = 1
      this.requestParams.search = {}
    },
    initData() {
      this.formatParams()
      this.getCoalOrderList()
    },
    handlePageChange({currentPage, pageSize}) {
      this.pageSize = pageSize
      this.page = currentPage
      this.getCoalOrderList()
    },
    getCoalOrderList() {
      this.isLoading = true
      let params = Object.assign({}, this.requestParams, {
        page: this.page,
        pageSize: this.pageSize,
        status: '1, 2, 3',
        dispatchType: 0
      })
      this.getOrderList(params).then(res => {
        if(res.paCode !== '000') {
          this.$message.error('获取用户订单失败' + res.paMsg);
        } else {
          this.totalCount = res.paData.totalCount;
          this.orderList = res.paData.requests;
        }
        this.isLoading = false
      })
    },
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD ddd HH:mm:ss')
    },
    refreshData() {
      this.formatParams()
      this.getCoalOrderList()
    },
    searchParamsKey(field) {
      switch (field) {
        case 'licensePlate':
          return 'licensePlate'
        case 'destination':
          return 'destination'
        case 'coalVar':
          return 'coalVar'
        default:
          return ''
      }
    },
    orderStatusText (status) {
      switch (status) {
        case 0:
          return '未支付'
        case 1:
          return '已支付'
        case 2:
          return '已派单'
        case 3:
          return '已退款'
        default:
          return '/'
      }
    },
    handleSetOrderStatus ({ id, status }) {
      this.setOrderStatus({
        coalOrderId: id,
        status
      }).then(res => {
        if (res.paCode === '000') {
          this.$message.success(res.paMsg)
          this.getCoalOrderList()
        } else {
          this.$message.error(res.paMsg)
        }
      })
    },
    handleSearch(column) {
      let searchKey = this.searchParamsKey(column.field)
      this.requestParams.search[searchKey] = this.searchKeyWords[searchKey]
      this.getCoalOrderList()
    },
    showHeaderReset(column) {
      let searchKey = this.searchParamsKey(column.field)
      return !!this.requestParams?.search?.[searchKey]
    },
    resetSearch(column) {
      let searchKey = this.searchParamsKey(column.field)
      delete this.requestParams?.search?.[searchKey]
      delete this.searchKeyWords[searchKey]
      this.getCoalOrderList()
    },
    handleEdit(row) {
      this.selectedOrderInfo = this.cloneDeep(row)
      this.orderEditVisible = true
    },
    handleRefund (row) {
      this.refundInfo = row
      this.refundLicense = ''
      this.showConfirmRefund = true
    },
    handleDelete(row) {
      this.setLoading(true)
      this.removeOrder({
        coalOrderId: row.coalOrderId
      }).then(res => {
        this.setLoading(false)
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '删除失败');
        } else {
          this.$message.success(res.paMsg || '删除成功');
          this.refreshData()
        }
      })
    },
    copyContent(licensePlate) {
      let input = document.createElement('input') // 创建input对象
      input.value = licensePlate // 设置复制内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      
      this.$message.success(`复制成功-${licensePlate}`)
    },
    handleEditOrder () {
      const params = {
        coalOrderId: this.selectedOrderInfo.coalOrderId,
        userName: this.selectedOrderInfo.userName,
        licensePlate: this.selectedOrderInfo.licensePlate,
        telNumber: this.selectedOrderInfo.telNumber,
        coalBillNumber: this.selectedOrderInfo.coalBillNumber
      }
      this.setLoading(true)
      this.editOrder(params).then(res => {
        this.setLoading(false)
        if (res.paCode !== '000') {
          this.$message.error(res.paMsg || '操作失败');
        } else {
          this.$message.success(res.paMsg || '操作成功');
          this.orderEditVisible = false
          this.refreshData()
        }
      })
    },
    quickFill () {
      this.selectedOrderInfo.coalBillNumber = this.billNumberDefaultValue
    },
    getImgPath (row) {
      if (row.freightRateList) {
        return `https://www.crr11.cn/public/${row.freightRateList}`
      } else {
        return '/'
      }
    },
    handleViewImg (row) {
      this.url = this.getImgPath(row)
      this.srcList = [this.url]
      this.$refs.previewImg.showViewer = true
    },
    handleExport () {
      this.$confirm('是否确认导出到本地Excel？(最多支持导出10000条数据)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          page: 1,
          pageSize: 10000,
          status: '1, 2, 3'
        }
        this.isLoading = true
        this.getOrderList(params).then(res => {
          this.isLoading = false
          if(res.paCode !== '000') {
            this.$message.error('获取用户订单失败' + res.paMsg);
          } else {
            const tableHeader = []
            const tableData = []
            const tableDataType = []
            const tableColumns = []
            res.paData.requests.forEach(item => {
              tableData.push([
                item.coalOrderId,
                item.userName,
                item.licensePlate,
                item.telNumber,
                item.destination,
                item.price,
                item.coalVar,
                item.coalBillNumber,
                this.orderStatusText(item.orderStatus),
                this.formatTime(item.orderTime),
                this.getImgPath(item),
                item.orderPrice,
                item.outTradeNo,
                item.remark
              ])
            })
            this.tableColumns.forEach(item => {
              if (item.field !== 'operation') {
                tableHeader.push(item.title)
                tableDataType.push({ isNumber: false })
                tableColumns.push({ width: item.width || 100 })
              }
            })
            let params = {
              tableData: [tableHeader, ...tableData],
              tableDataType: tableDataType,
              tableColumns: tableColumns,
              fileName: dayjs().format('用户订单列表')
            }
            tableExport(params)
          }
        })
      })
    },
    handleCheckRefundLicense () {
      this.setLoading(true)
      this.checkRefundLicense({
        refundLicense: this.refundLicense
      }).then(checkRes => {
        this.setLoading(false)
        if (checkRes.paCode === '000') {
          if (checkRes.paData) {
            this.refundOrder({
              outTradeNo: this.refundInfo.outTradeNo,
              totalAmount: this.refundInfo.orderPrice,
              refundMoney: this.refundInfo.orderPrice - this.orderManageSetting.refundDeduct.data
            }).then(res => {
              if (res.paCode !== '000') {
                this.$message.error('退款异常');
              } else {
                this.$message.success(res.paMsg || '退款中');
                this.showConfirmRefund = false
                this.refreshData()
              }
            })
          } else {
            this.$message.error('密码错误')
          }
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.main-container {
  width: 100%;
  height: 100%;
  
  /deep/ .is-assigned-order {
    background-color: #ff5232;
    color: white;
  }
  .top-content {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
  }
  .total-count-content {
    margin-right: 8px;
    font-size: 14px;
    color: #5b6b7e;
    border: 1px solid #5b6b7e;
    border-radius: 5px;
    padding: 1px 6px;
    font-weight: bold;
  }
  .title-content {
    margin-right: 16px;
  }
  .refresh-icon {
    font-size: 18px;
    color: #4a90e2;
  }
  .reset-btn {
    margin-right: 6px;
    font-weight: lighter;
    color: #6891c0;
  }
  .operate-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .el-divider--vertical {
      margin: 0;
    }
    
    .edit-btn {
      color: #4a90e2;
    }
    
    .del-btn {
      color: #b81414;
    }
  }
  .license-plate-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .order-info-content {
    padding: 16px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
}
.search-input-wrapper {
  display: flex;
  width: 330px;
  height: 34px;
  align-items: center;
  .search-input {
    flex: 1;
    margin-left: 0;
  }
  /deep/ .el-input__inner {
    border-radius: 5px;
    height: 35px;
  }
  .search-confirm {
    color: #4a90e2;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
  }
}
/deep/ .vxe-table--render-default .vxe-header--column:not(.col--ellipsis) {
  padding: 8px 0;
}
</style>