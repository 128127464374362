import axios from 'axios'
import baseData from '@/baseData.js'
const baseUrl = baseData.baseUrl

export default {
  dispatchedOrder(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/dispatchedOrder`, params)
  },
  addDispatch(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/addDispatch`, params)
  },
  editDispatch(params) {
    return axios.post(`${baseUrl}/pa/coalOrder/editDispatch`, params)
  },
  getBlacklist (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/getBlacklist`, params)
  },
  addBlacklist (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/addBlacklist`, params)
  },
  removeBlacklist (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/removeBlacklist`, params)
  },
  getDispatchDetail (params) {
    return axios.post(`${baseUrl}/pa/coalOrder/dispatchDetail`, params)
  }
}