import updateFreightRateHome from "@/components/updateFreightRate/index.vue";
import upload from "@/components/updateFreightRate/upload.vue";

export default {
  router: {
    path: "/updateFreightRate",
    name: "updateFreightRate",
    component: updateFreightRateHome,
    props: true,
    children: [
      {
        path: "upload",
        name: "upload",
        component: upload,
        props: true
      }
    ]
  },
}