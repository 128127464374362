<template>
  <div id="app">
    <div v-if="!loginStatus">
      <login @login-success="setUser"></login>
    </div>
    <div v-else class="main-container home-container">
      <HomePage></HomePage>
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import HomePage from "@/components/home/index.vue"
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "App",
  components: {
    Login,
    HomePage,
  },
  data() {
    return {
      user: {},
      // base_url: 'https://crr11.cn/api'
    };
  },
  computed: {
    ...mapGetters(['loginStatus']),
  },
  methods: {
    ...mapActions({
      getSetting: 'getSetting',
    }),
    ...mapMutations([
      'setLoginStatus',
      'setState'
    ]),
    initData () {
      this.getSetting({
        settingGroup: 'common'
      }).then(res => {
        if (res.paCode === '000') {
          this.setState({
            stateName: 'commonConfig',
            value: res.paData
          })
        }
      })
    },
    checkLoginStatus() {
      const loggedIn = localStorage.getItem("loggedIn");
      const user = JSON.parse(localStorage.getItem("user"));
      console.log('loggedIn:', loggedIn)
      this.setLoginStatus(!!loggedIn)
      // this.loggedIn = loggedIn === "true";
      this.user = user;
    },
    setUser(user) {
      this.setLoginStatus(true);
      this.user = user;

      // 在登录成功后将 destination 保存到 localStorage
      if (user.adm_id === "Guangtian") {
        localStorage.setItem("destination", "广田");
      } else if (user.adm_id === "Hongyun") {
        localStorage.setItem("destination", "鸿运");
      } else if (user.adm_id === "Shunchang") {
        localStorage.setItem("destination", "顺昌");
      } else if (user.adm_id === "Xinhuatai") {
        localStorage.setItem("destination", "新华太");
      } else if (user.adm_id === "Fuding") {
        localStorage.setItem("destination", "富鼎");
      } else if (user.adm_id === "Ceshi") {
        localStorage.setItem("destination", "测试");
      } else {
        localStorage.removeItem("destination");
      }
    },
  },
  created() {
    this.initData()
    this.checkLoginStatus();
  },
};
</script>

<style scoped>
#app {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.home-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #f8f9fb;
}
</style>
<style src="@/assets/styles/main.css"></style>
<style src="@/assets/styles/base.css"></style>
